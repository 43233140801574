<div class="explanations-tools">
  @if (breakpointService.isDesktop()) {
    <p class="explanations-tools__text">
      RoutePerfect members can enhance their trip with <strong>travel perks</strong> via our members’ app
    </p>
  } @else {
    <p class="explanations-tools__text">RoutePerfect Perks</p>
  }

  <ul class="explanations-tools__permissions">
    @for (permission of permissions; track $index) {
      <li class="explanations-tools__permission">
        <img class="explanations-tools__permission--icon" [src]="'assets/images/' + permission.icon"
             [alt]="permission.title | translate">
        <div class="explanations-tools__item">
          <p class="explanations-tools__permission--title">
            <span>{{ permission.title | translate }}</span>
            @if (permission.titleImage) {
              <img [src]="permission.titleImage" [alt]="permission.title | translate" style="height: 28px">
            }
          </p>
          <p class="explanations-tools__permission--text">{{ permission.description | translate }}</p>
        </div>
      </li>
    }
  </ul>
</div>
