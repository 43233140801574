import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Subject, takeUntil } from 'rxjs';

import { NewAuthService } from 'src/app/core/services/new-auth.service';
import { BreakpointService } from 'src/app/core/services/breakpoint.service';

import { AuthDialogComponent } from '../../auth-dialog.component';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['../../auth-dialog.component.scss'],
  standalone: false,
})
export class SocialLoginComponent implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { saveEvent: boolean },
    private newAuthService: NewAuthService,
    private dialogRef: MatDialogRef<AuthDialogComponent>,
    protected readonly breakpointService: BreakpointService,
  ) {
    this.newAuthService.socialLoginDone
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.dialogRef.close(true));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
