<div class="auth-box" [class.auth-box--save-event]="isSaveEvent">
  @if (breakpointService.isDesktop()) {
    <app-social-login></app-social-login>
  }

  <form class="auth-box__form" [formGroup]="signUpForm">
    <div class="field-box">
      <label class="field-box__label">
        @if (breakpointService.isDesktop()) {
          {{ 'AUTH.EMAIL' | translate }}*
        } @else {
          Email address
        }
      </label>

      @if (breakpointService.isMobile()) {
        <mat-form-field appearance="fill">
          <input
                 type="text"
                 formControlName="email"
                 placeholder="Enter your email address"
                 matInput
          />
          <mat-icon [svgIcon]="eSvgIcons.EMAIL" matSuffix></mat-icon>
        </mat-form-field>
      } @else {
        <input class="field-box__input"
               type="text"
               formControlName="email"
               placeholder="{{'AUTH.EMAIL_PLACEHOLDER' | translate}}"
        />
      }

      @if (signUpForm.get('email').hasError('email') && signUpForm.get('email').touched) {
        <p class="field-box__error">
          {{ 'VALIDATION.ENTER_VALID_EMAIL' | translate }}
        </p>
      }
      @if (signUpForm.get('email').hasError('required') && signUpForm.get('email').touched) {
        <p class="field-box__error">
          {{ 'VALIDATION.FIELD_REQUIRED' | translate }}
        </p>
      }
    </div>

    <div class="field-box">
      <label class="field-box__label">{{ 'AUTH.FIRST_NAME' | translate }}</label>

      @if (breakpointService.isMobile()) {
        <mat-form-field appearance="fill">
          <input
                 type="text"
                 formControlName="first_name"
                 placeholder="Enter your first name"
                 matInput
          />
          <mat-icon [svgIcon]="eSvgIcons.PERSON" matSuffix></mat-icon>
        </mat-form-field>
      } @else {
        <input class="field-box__input"
               type="text"
               formControlName="first_name"
               placeholder="{{'AUTH.FIRST_NAME_PLACEHOLDER' | translate}}"
        />
      }

      @if (signUpForm.get('first_name').hasError('required') && signUpForm.get('first_name').touched) {
        <p class="field-box__error">
          {{ 'VALIDATION.FIELD_REQUIRED' | translate }}
        </p>
      }
    </div>

    <div class="field-box">
      <label class="field-box__label">{{ 'AUTH.LAST_NAME' | translate }}</label>

      @if (breakpointService.isMobile()) {
        <mat-form-field appearance="fill">
          <input
                 type="text"
                 formControlName="last_name"
                 placeholder="Enter your last name"
                 matInput
          />
          <mat-icon [svgIcon]="eSvgIcons.PERSON" matSuffix></mat-icon>
        </mat-form-field>
      } @else {
        <input class="field-box__input"
               type="text"
               formControlName="last_name"
               placeholder="{{'AUTH.LAST_NAME_PLACEHOLDER' | translate}}"
        />
      }

      @if (signUpForm.get('last_name').hasError('required') && signUpForm.get('last_name').touched) {
        <p class="field-box__error">
          {{ 'VALIDATION.FIELD_REQUIRED' | translate }}
        </p>
      }
    </div>

    <div class="field-box field-box--small-margin">
      <label class="field-box__label">
        @if(breakpointService.isDesktop()) {
          {{ 'AUTH.PASSWORD' | translate }}*
        } @else {
          Password
        }
      </label>

      @if (breakpointService.isMobile()) {
        <div class="password">
          <mat-form-field appearance="fill">
            <input
                   type="password"
                   formControlName="pass"
                   placeholder="Create your password"
                   matInput
                   #password
            />
            <mat-icon [svgIcon]="eSvgIcons.AUTH_LOCK" matSuffix></mat-icon>
          </mat-form-field>
        </div>
        <button class="field-box__password-btn"
                (click)="password.type = (password.type === 'password' ? 'text' : 'password')">
          <img src="assets/svg/ic-{{password.type === 'password' ? 'show-eye' : 'hide-eye'}}.svg"
               alt="toggle-eye-icon">
        </button>
      } @else {
        <input class="field-box__input password"
               type="password"
               formControlName="pass"
               [placeholder]="'AUTH_FIELD.PASSWORD_PLACEHOLDER' | translate"
               #password
        />
        <button class="field-box__password-btn"
                (click)="password.type = (password.type === 'password' ? 'text' : 'password')">
          <img src="assets/svg/ic-{{password.type === 'password' ? 'show-eye' : 'hide-eye'}}.svg"
               alt="toggle-eye-icon">
        </button>
      }

      @if (signUpForm.get('pass').hasError('required') && signUpForm.get('pass').touched) {
        <p class="field-box__error">
          {{ 'VALIDATION.FIELD_REQUIRED' | translate }}
        </p>
      }
    </div>

    @if (breakpointService.isMobile()) {
      <div class="switch-box__link form-input--checkbox">
        <mat-checkbox formControlName="subscribe" (change)="handleSubscribeToMediaChange($event.checked)">
          I agree to receive marketing materials, updates, and promotional offers from RoutePerfect
        </mat-checkbox>
      </div>

      <div class="auth-box__actions">
        <button class="btn-default btn-orange btn-md btn-mobile"
                (click)="submit()"
                [disabled]="signUpForm.invalid || loading" [class.loading-btn-flex]="loadingService.isPageLoading$ | async">
          @if(loadingService.isPageLoading$ | async) {
            <div class="loading-btn">
              <img ngSrc="assets/svg/circles.svg" width="20" height="20" alt="loading">
            </div>
          }
          <span>
            {{ 'ACTIONS.SIGN_UP' | translate }}
          </span>
        </button>
      </div>
      <div class="auth-box__terms">
        By signing up, you agree to RoutePerfect's <a [routerLink]="[eRoutes.SLASH, eRoutes.PRIVACY_POLICY]" (click)="closeModal(false)">terms and conditions</a>.
      </div>
    }

    @if (breakpointService.isDesktop()) {
      <div class="switch-box__link form-input--checkbox">
        <mat-checkbox formControlName="subscribe" (change)="handleSubscribeToMediaChange($event.checked)">
          I agree to receive marketing materials, updates, and promotional offers from RoutePerfect
        </mat-checkbox>
      </div>

      <div class="auth-box__actions">
        <button class="btn-default btn-primary"
                (click)="submit()"
                [disabled]="signUpForm.invalid || loading" [class.loading-btn-flex]="loadingService.isPageLoading$ | async">
          @if(loadingService.isPageLoading$ | async) {
            <div class="loading-btn">
              <img ngSrc="assets/svg/circles.svg" width="20" height="20" alt="loading">
            </div>
          }
          <span>
            {{ 'ACTIONS.SIGN_UP' | translate }}
          </span>
        </button>
      </div>
    }


    @if(breakpointService.isDesktop()) {
      <div class="switch-box">
        <div class="switch-box__link">
          <span>{{ 'AUTH.LOG_IN_PROMPT' | translate }}</span>
          <button class="m-lr-5"
                  (click)="authPage.emit(eAuthPage.LOGIN)">
            {{ 'ACTIONS.LOG_IN' | translate }}
          </button>
        </div>

        <div class="switch-box__private-policy">
          <span [innerHTML]="'AUTH.SIGN_IN.PRIVATE_POLICY.DESCRIPTION' | translate | safeHtml"></span>&nbsp;
          <a href="/terms-n-conditions" target="_blank">{{ 'AUTH.SIGN_IN.PRIVATE_POLICY.TITLE' | translate }}</a>
        </div>
      </div>
    }

    @if (breakpointService.isMobile()) {
      <app-social-login></app-social-login>

      <div class="switch-box">
        <div class="switch-box__link">
          <span>Already have an account?</span>
          <button class="m-lr-5" style="color: #375C7F"
                  (click)="authPage.emit(eAuthPage.LOGIN)">
            Sign In
          </button>
        </div>
      </div>
    }
  </form>
</div>
