import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { DeviceDetectorService } from 'ngx-device-detector';

import { ERoutes } from '../../../../core/enums/routes.enum';
import { Config } from '../../../../core/configs/config';

import { CloudinaryService } from '../../../../core/services/cloudinary.service';
import { SessionStorageService } from '../../../../core/services/session-storage.service';

import { EAuthPage } from '../auth-dialog.component';
import { ESvgIcons } from '../../../../core/enums/svg-icons';
import { BreakpointService } from '../../../../core/services/breakpoint.service';
import { LoadingService } from '../../../../core/services/loading.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../auth-dialog.component.scss'],
  standalone: false,
})
export class LoginComponent implements OnInit {
  @Input() loading: boolean;
  @Input() isSaveEvent: boolean;
  @Output() authPage: EventEmitter<EAuthPage> = new EventEmitter<EAuthPage>();
  @Output() submitEvent: EventEmitter<{ email: string; pass: string }> =
    new EventEmitter<{ email: string; pass: string }>();

  public readonly eAuthPage = EAuthPage;
  public loginForm: UntypedFormGroup;
  public readonly breakpointConfig = this.device;
  bannerImage = Config.cdnUrl + 'app/images/homepageBodyImages/bg_hptop.jpeg';

  constructor(
    private dialogRef: MatDialogRef<LoginComponent>,
    private router: Router,
    private device: DeviceDetectorService,
    private cloudinaryService: CloudinaryService,
    private sessionStorageService: SessionStorageService,
    protected readonly breakpointService: BreakpointService,
    protected readonly loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.bannerImage = this.cloudinaryService.getCloudImage(
      this.bannerImage,
      true,
    );
    this.initForm();
  }

  closeModal(closeResult: boolean): void {
    this.dialogRef.close(closeResult);
  }

  submit(): void {
    this.submitEvent.emit(this.loginForm.getRawValue());
  }

  private initForm(): void {
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
      pass: new UntypedFormControl('', [Validators.required]),
    });

    this.checkResetPassEmail();
  }

  private checkResetPassEmail(): void {
    const resetPassEmail =
      this.sessionStorageService.getSessionStorage('resetPassEmail');

    if (resetPassEmail) {
      this.loginForm.get('email').patchValue(resetPassEmail);
      this.sessionStorageService.removeItem('resetPassEmail');
    }
  }

  protected readonly eSvgIcons = ESvgIcons;
}
