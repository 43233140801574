import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { DeviceDetectorService } from 'ngx-device-detector';

import { SessionStorageService } from 'src/app/core/services/session-storage.service';

import { EAuthPage } from '../auth-dialog.component';
import { BreakpointService } from "../../../../core/services/breakpoint.service";
import { ESvgIcons } from "../../../../core/enums/svg-icons";
import { LoadingService } from "../../../../core/services/loading.service";

@Component({
    selector: 'app-forgot-pass',
    templateUrl: './forgot-pass.component.html',
    styleUrls: ['../auth-dialog.component.scss'],
    standalone: false
})
export class ForgotPassComponent implements OnInit {
  @Input() loading: boolean;
  @Input() congratsView: boolean;
  @Input() isSaveEvent: boolean;
  @Output() authPage: EventEmitter<EAuthPage> = new EventEmitter<EAuthPage>();
  @Output() submitEvent: EventEmitter<{ email: string }> = new EventEmitter<{
    email: string;
  }>();

  public readonly eAuthPage = EAuthPage;
  public forgotPassForm: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<ForgotPassComponent>,
    private sessionStorageService: SessionStorageService,
    protected readonly breakpointService: BreakpointService,
    protected readonly loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  closeModal(closeResult: boolean): void {
    this.dialogRef.close(closeResult);
  }

  submit(): void {
    this.submitEvent.emit(this.forgotPassForm.getRawValue());
  }

  goToLoginWithEmail(): void {
    this.sessionStorageService.setSessionStorage(
      'resetPassEmail',
      this.forgotPassForm.get('email').value,
    );
    this.authPage.emit(EAuthPage.LOGIN);
  }

  private initForm(): void {
    this.forgotPassForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required]),
    });
  }

  protected readonly eSvgIcons = ESvgIcons;
}
