<div class="auth-box" [class.auth-box--save-event]="isSaveEvent">
  @if (breakpointService.isDesktop()) {
    <app-social-login></app-social-login>
  }

  <form class="auth-box__form auth-box__form--sign-in" [formGroup]="loginForm">
    <div class="field-box">
      <label class="field-box__label">
        @if (breakpointService.isDesktop()) {
          {{ 'AUTH.EMAIL' | translate }}*
        } @else {
          Email address
        }
      </label>

      @if (breakpointService.isMobile()) {
        <mat-form-field appearance="fill">
          <input
            type="text"
            formControlName="email"
            placeholder="{{'AUTH.EMAIL_PLACEHOLDER' | translate}}"
            matInput
          />
          <mat-icon [svgIcon]="eSvgIcons.EMAIL" matSuffix></mat-icon>
        </mat-form-field>
      } @else {
        <input class="field-box__input"
               type="text"
               formControlName="email"
               placeholder="{{'AUTH.EMAIL_PLACEHOLDER' | translate}}"
        />
      }

      @if (loginForm.get('email').hasError('email') && loginForm.get('email').touched) {
        <p class="field-box__error">
          {{ 'VALIDATION.ENTER_VALID_EMAIL' | translate }}
        </p>
      }

      @if (loginForm.get('email').hasError('required') && loginForm.get('email').touched) {
        <p class="field-box__error">
          {{ 'VALIDATION.FIELD_REQUIRED' | translate }}
        </p>
      }
    </div>

    <div class="field-box" [class.field-box--space-none]="breakpointService.isDesktop()">
      <label class="field-box__label">
        @if(breakpointService.isDesktop()) {
          {{ 'AUTH_FIELD.PASSWORD' | translate }}*
        } @else {
          Password
        }
      </label>

      @if (breakpointService.isMobile()) {
        <div class="password">
          <mat-form-field appearance="fill">
            <input type="password" formControlName="pass" matInput
                   placeholder="{{'AUTH.PASSWORD_PLACEHOLDER' | translate}}" #password>
            <mat-icon [svgIcon]="eSvgIcons.AUTH_LOCK" matSuffix></mat-icon>
          </mat-form-field>
        </div>
        <button class="field-box__password-btn"
                (click)="password.type = (password.type === 'password' ? 'text' : 'password')">
          <img src="assets/svg/ic-{{password.type === 'password' ? 'show-eye' : 'hide-eye'}}.svg"
               alt="toggle-eye-icon">
        </button>
      } @else {
        <input class="field-box__input password"
               type="password"
               formControlName="pass"
               placeholder="{{'AUTH.PASSWORD_PLACEHOLDER' | translate}}"
               #password
        />
        <button class="field-box__password-btn"
                (click)="password.type = (password.type === 'password' ? 'text' : 'password')">
          <img src="assets/svg/ic-{{password.type === 'password' ? 'show-eye' : 'hide-eye'}}.svg"
               alt="toggle-eye-icon">
        </button>
      }
      @if (loginForm.get('pass').hasError('required') && loginForm.get('pass').touched) {
        <p class="field-box__error">
          {{ 'VALIDATION.FIELD_REQUIRED' | translate }}
        </p>
      }
    </div>

    @if (breakpointService.isMobile()) {
      <div class="auth-box__forgot">
        <button class="forgot-pass-btn" (click)="authPage.emit(eAuthPage.FORGOT_PASS)">
          Forgot password?
        </button>
      </div>

      <div class="auth-box__actions">
        <button class="btn-default btn-orange btn-md btn-mobile"
                (click)="submit()"
                [disabled]="loginForm.invalid || loading" [class.loading-btn-flex]="loadingService.isPageLoading$ | async">
          @if(loadingService.isPageLoading$ | async) {
            <div class="loading-btn">
              <img ngSrc="assets/svg/circles.svg" width="20" height="20" alt="loading">
            </div>
          }
          <span>
            {{ 'ACTIONS.LOG_IN' | translate }}
          </span>
        </button>
      </div>
    }

    @if (breakpointService.isDesktop()) {
      <div class="auth-box__actions">
        <button class="btn-default btn-primary"
                (click)="submit()"
                [disabled]="loginForm.invalid || loading" [class.loading-btn-flex]="loadingService.isPageLoading$ | async">
          @if(loadingService.isPageLoading$ | async) {
            <div class="loading-btn">
              <img ngSrc="assets/svg/circles.svg" width="20" height="20" alt="loading">
            </div>
          }
          <span>
            {{ 'ACTIONS.LOG_IN' | translate }}
          </span>
        </button>

        <button class="forgot-pass-btn" (click)="authPage.emit(eAuthPage.FORGOT_PASS)">
          {{ 'ACTIONS.FORGOT_PASSWORD' | translate }}
        </button>
      </div>

      <div class="switch-box">
        <div class="switch-box__link">
          <span>{{ 'AUTH.SIGN_UP_PROMPT' | translate }}</span>
          <button class="m-lr-5"
                  (click)="authPage.emit(eAuthPage.SIGN_UP)">
            {{ 'ACTIONS.SIGN_UP' | translate }}
          </button>
        </div>

        <div class="switch-box__private-policy">
          <span [innerHTML]="'AUTH.SIGN_IN.PRIVATE_POLICY.DESCRIPTION' | translate | safeHtml"></span>&nbsp;
          <a routerLink="/terms-n-conditions"
             (click)="closeModal(false)">
            {{ 'AUTH.SIGN_IN.PRIVATE_POLICY.TITLE' | translate }}
          </a>
        </div>
      </div>
    }
  </form>

  @if (breakpointService.isMobile()) {
    <app-social-login></app-social-login>

    <div class="switch-box">
      <div class="switch-box__link">
        <span>{{ 'AUTH.SIGN_UP_PROMPT' | translate }}</span>
        <button class="m-lr-5"
                (click)="authPage.emit(eAuthPage.SIGN_UP)">
          {{ 'ACTIONS.SIGN_UP' | translate }}
        </button>
      </div>
    </div>
  }
</div>


