import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { Config } from 'src/app/core/configs/config';
import { ERoutes } from 'src/app/core/enums/routes.enum';
import { ESvgIcons } from 'src/app/core/enums/svg-icons';

import { LoadingService } from 'src/app/core/services/loading.service';
import { NewAuthService } from 'src/app/core/services/new-auth.service';
import { CloudinaryService } from 'src/app/core/services/cloudinary.service';
import { BreakpointService } from 'src/app/core/services/breakpoint.service';

import { EAuthPage } from '../auth-dialog.component';
import { ESubscribeNewLatter } from 'src/app/modules/user-account/user-settings/user-settings.component';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['../auth-dialog.component.scss'],
  standalone: false,
})
export class SignUpComponent implements OnInit {
  @Input() loading: boolean;
  @Input() isSaveEvent: boolean;
  @Output() authPage: EventEmitter<EAuthPage> = new EventEmitter<EAuthPage>();
  @Output() submitEvent: EventEmitter<any> = new EventEmitter<any>();

  public readonly eAuthPage = EAuthPage;
  public readonly eRoutes = ERoutes;
  public signUpForm: UntypedFormGroup;
  bannerImage = Config.cdnUrl + 'app/images/homepageBodyImages/bg_hptop.jpeg';

  protected readonly eSvgIcons = ESvgIcons;

  constructor(
    private dialogRef: MatDialogRef<SignUpComponent>,
    private cloudinaryService: CloudinaryService,
    private newAuthService: NewAuthService,
    protected readonly loadingService: LoadingService,
    protected readonly breakpointService: BreakpointService,
  ) {}

  ngOnInit(): void {
    this.bannerImage = this.cloudinaryService.getCloudImage(
      this.bannerImage,
      true,
    );
    this.initForm();
  }

  closeModal(closeResult: boolean): void {
    this.dialogRef.close(closeResult);
  }

  getAbstractControl(controlName: string): AbstractControl {
    return this.signUpForm.get(controlName);
  }

  submit(): void {
    this.checkSubscribeNewLetter();
    this.setUserName();
    this.submitEvent.emit(this.signUpForm.getRawValue());
  }

  handleSubscribeToMediaChange(subscribe: boolean): void {
    this.newAuthService.subscribe.set(subscribe);
  }

  private initForm(): void {
    this.signUpForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email,
      ]),
      name: new UntypedFormControl(''),
      first_name: new UntypedFormControl('', [Validators.minLength(2)]),
      last_name: new UntypedFormControl('', [Validators.minLength(2)]),
      pass: new UntypedFormControl('', [Validators.required]),
      repass: new UntypedFormControl(''),
      subscribe: new UntypedFormControl(true),
    });
    this.newAuthService.subscribe.set(true);
  }

  private checkSubscribeNewLetter(): void {
    const sub = this.getAbstractControl('subscribe');
    this.getAbstractControl('repass').patchValue(
      this.getAbstractControl('pass').value,
    );

    if (sub.value) {
      sub.patchValue(ESubscribeNewLatter.YES);
      return;
    }

    sub.patchValue(ESubscribeNewLatter.NO);
  }

  private setUserName(): void {
    const { first_name, last_name } = this.signUpForm.value;
    const nameFormControl = this.signUpForm.controls['name'];

    if (nameFormControl) {
      nameFormControl.setValue(`${first_name} ${last_name}`);
    }
  }
}
