import { Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { BreakpointService } from 'src/app/core/services/breakpoint.service';

@Component({
  selector: 'app-explanations-tools',
  templateUrl: './explanations-tools.component.html',
  styleUrls: ['./explanations-tools.component.scss'],
  imports: [TranslateModule],
})
export class ExplanationsToolsComponent {
  readonly permissions = [
    {
      title: 'EXPLANATIONS_TOOLS.PERMISSIONS.EN_ROUTE',
      description: 'EXPLANATIONS_TOOLS.PERMISSIONS.EN_ROUTE_DESCRIPTION',
      icon: 'en-route.png',
      titleImage: '',
    },
    {
      title: 'EXPLANATIONS_TOOLS.PERMISSIONS.EN_EVENT',
      description: 'EXPLANATIONS_TOOLS.PERMISSIONS.EN_EVENT_DESCRIPTION',
      icon: 'events-calendar.png',
      titleImage: '',
    },
    {
      title: 'EXPLANATIONS_TOOLS.PERMISSIONS.ORGANIZER',
      description: 'EXPLANATIONS_TOOLS.PERMISSIONS.ORGANIZER_DESCRIPTION',
      icon: 'organizer.png',
      titleImage: '',
    },
    {
      title: 'EXPLANATIONS_TOOLS.PERMISSIONS.AUDIO',
      description: 'EXPLANATIONS_TOOLS.PERMISSIONS.AUDIO_DESCRIPTION',
      icon: 'audi-city-guide.png',
      titleImage:
        'https://res.cloudinary.com/djxk74uhg/image/fetch/f_auto/https://cdn.routeperfect.com/app/images/images/voice-map.png?_a=ATFGlAA0',
    },
  ];

  constructor(protected readonly breakpointService: BreakpointService) {}
}
