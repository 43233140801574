import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Subject } from 'rxjs';

import { includes, isEmpty } from 'src/app/shared/helpers/helpers';
import { TripPlannerMode } from 'src/app/core/settings/trip-planner-mode';

import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  private updateSessionStorageSubject$ = new Subject<string>();
  private readonly availableStorageItems: any = {
    packageData: false,
    tripDestinations: false,
    tripPlannerDataLoaded: false,
    planningMode: TripPlannerMode.auto,
  };
  private readonly sessionStorage: Storage =
    this.document.defaultView?.localStorage;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public triggerUpdateSessionStorageSubject = (key: string) =>
    this.updateSessionStorageSubject$.next(key);

  public resetSessionStorage(exclude: string[] = []): void {
    Object.keys(this.availableStorageItems).forEach((key: string) => {
      if (!includes(exclude, key)) {
        this.setSessionStorage(key, this.availableStorageItems[key]);
      }
    });
  }

  public setSessionStorage(key: string, value: any): void {
    if (key === 'tripDestinations' && !value) {
      return;
    }
    if (typeof value === 'undefined') {
      value = null;
    }

    const simplifyValue = JSON.stringify(value);
    if (this.sessionStorage) {
      this.sessionStorage.setItem(key, simplifyValue);
    }
  }

  public getSessionStorage(key: string, defaultValue: any = ''): any {
    if (
      this.sessionStorage &&
      this.sessionStorage.getItem(key) &&
      this.sessionStorage.getItem(key) !== 'undefined'
    ) {
      const jsonValue = JSON.parse(this.sessionStorage.getItem(key));
      if (key === 'tripDetails' && !isEmpty(jsonValue)) {
        if (moment(jsonValue.tripStartDate).isValid()) {
          jsonValue.tripStartDate = new Date(jsonValue.tripStartDate);
        } else {
          jsonValue.tripStartDate = '';
        }

        if (moment(jsonValue.tripEndDate).isValid()) {
          jsonValue.tripEndDate = new Date(jsonValue.tripEndDate);
        } else {
          jsonValue.tripEndDate = '';
        }
      }

      return jsonValue || defaultValue;
    } else {
      return defaultValue;
    }
  }

  public removeItem(key: string): void {
    if (sessionStorage) {
      sessionStorage.removeItem(key);
    }
  }
}
