<div class="auth-box" [class.auth-box--save-event]="isSaveEvent">

  @if (breakpointService.isDesktop()) {
    <app-social-login></app-social-login>
  }

  <form class="auth-box__form" [formGroup]="forgotPassForm">
    <div class="field-box">
      <label class="field-box__label">{{ 'AUTH.EMAIL' | translate }}</label>

      @if (breakpointService.isMobile()) {
        <mat-form-field appearance="fill">
          <input
                 type="text"
                 formControlName="email"
                 placeholder="{{'AUTH.EMAIL_PLACEHOLDER' | translate}}"
                 matInput
          />
          <mat-icon [svgIcon]="eSvgIcons.EMAIL" matSuffix></mat-icon>
        </mat-form-field>
      } @else {
        <input class="field-box__input"
               type="text"
               formControlName="email"
               placeholder="{{'AUTH.EMAIL_PLACEHOLDER' | translate}}"
        />
      }

      @if (forgotPassForm.get('email').hasError('email') && forgotPassForm.get('email').touched) {
        <p class="field-box__error">
          {{ 'VALIDATION.ENTER_VALID_EMAIL' | translate }}
        </p>
      }
      @if (forgotPassForm.get('email').hasError('required') && forgotPassForm.get('email').touched) {
        <p class="field-box__error">
          {{ 'VALIDATION.FIELD_REQUIRED' | translate }}
        </p>
      }
    </div>

    @if (breakpointService.isDesktop()) {
      <div class="switch-box">
        <div class="switch-box__link">
          <span>{{ 'AUTH.SIGN_UP_PROMPT' | translate }}</span>
          <button class="m-lr-5"
                  (click)="authPage.emit(eAuthPage.SIGN_UP)">
            {{ 'ACTIONS.SIGN_UP' | translate }}
          </button>
        </div>

        <div class="switch-box__link">
          <span>{{ 'AUTH.LOG_IN_PROMPT' | translate }}</span>
          <button class="m-lr-5"
                  (click)="authPage.emit(eAuthPage.LOGIN)">
            {{ 'ACTIONS.LOG_IN' | translate }}
          </button>
        </div>
      </div>
    }
  </form>

  @if (!congratsView) {
    <div class="auth-box__actions">
      @if(breakpointService.isDesktop()) {
        <button class="btn-default btn-primary"
                (click)="submit()"
                [disabled]="forgotPassForm.invalid || loading" [class.loading-btn-flex]="loadingService.isPageLoading$ | async">
          @if(loadingService.isPageLoading$ | async) {
            <div class="loading-btn">
              <img ngSrc="assets/svg/circles.svg" width="20" height="20" alt="loading">
            </div>
          }
          <span>
            {{ 'ACTIONS.SUBMIT' | translate }}
          </span>
        </button>
      } @else {
        <button class="btn-default btn-orange btn-md btn-mobile"
                (click)="submit()"
                [disabled]="forgotPassForm.invalid || loading" [class.loading-btn-flex]="loadingService.isPageLoading$ | async">
          @if(loadingService.isPageLoading$ | async) {
            <div class="loading-btn">
              <img ngSrc="assets/svg/circles.svg" width="20" height="20" alt="loading">
            </div>
          }
          <span>
            {{ 'ACTIONS.SUBMIT' | translate }}
          </span>
        </button>
      }
    </div>
  } @else {
    <div class="auth-box__congrats-view">
      <h3>{{ 'AUTH.CONGRATULATIONS' | translate }}</h3>
      <p>
        {{ 'AUTH.WE_SEND_NEW_PASS' | translate }} <strong>{{ forgotPassForm.get('email').value }}</strong>
      </p>
      <button class="btn-default btn-primary"
              (click)="goToLoginWithEmail()">
        {{ 'ACTIONS.OK' | translate }}
      </button>
    </div>
  }

  @if (breakpointService.isMobile()) {
    <div class="switch-box">
      <div class="switch-box__link">
        <span>{{ 'AUTH.SIGN_UP_PROMPT' | translate }}</span>
        <button class="m-lr-5"
                (click)="authPage.emit(eAuthPage.SIGN_UP)">
          {{ 'ACTIONS.SIGN_UP' | translate }}
        </button>
      </div>

      <div class="switch-box__link">
        <span>{{ 'AUTH.LOG_IN_PROMPT' | translate }}</span>
        <button class="m-lr-5"
                (click)="authPage.emit(eAuthPage.LOGIN)">
          {{ 'ACTIONS.LOG_IN' | translate }}
        </button>
      </div>
    </div>
  }
</div>
