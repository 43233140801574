<div class="authentication-social">
    @if (breakpointService.isDesktop()) {
        <div class="authentication-social__buttons">
            <div class="authentication-social__asl-box">
                <asl-google-signin-button
                        text="signin_with"
                        type="standard"
                        locale="en_US"
                        size="large"
                        [width]="220"
                ></asl-google-signin-button>
            </div>
        </div>
        <div class="authentication-social__separator">
            <span>{{ 'OR' | translate }}</span>
        </div>
    } @else {
        <div class="authentication-social__separator">
            <span>Or sign in with social media</span>
        </div>
        <div class="authentication-social__buttons">
            <div class="authentication-social__asl-box">
                <asl-google-signin-button
                        text="signin_with"
                        type="standard"
                        locale="en_US"
                        size="large"
                        [width]="220"
                ></asl-google-signin-button>
            </div>
        </div>
    }
</div>
