import { NgModule } from '@angular/core';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';

import { MatIcon } from '@angular/material/icon';
import {
  MatFormField,
  MatLabel,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatCheckbox } from '@angular/material/checkbox';

import { TranslateModule } from '@ngx-translate/core';

import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';

import { SafeUrlPipe } from '../../pipes/safe-url.pipe';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';

import { LoginComponent } from './login/login.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { AuthDialogComponent } from './auth-dialog.component';
import { ForgotPassComponent } from './forgot-pass/forgot-pass.component';
import { SocialLoginComponent } from './components/social-login/social-login.component';
import { ExplanationsToolsComponent } from './components/explanations-tools/explanations-tools.component';

@NgModule({
  declarations: [
    AuthDialogComponent,
    LoginComponent,
    SignUpComponent,
    ForgotPassComponent,
    SocialLoginComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    GoogleSigninButtonModule,
    TranslateModule,
    RouterLink,
    AsyncPipe,
    SafeUrlPipe,
    SafeHtmlPipe,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatSuffix,
    MatCheckbox,
    NgOptimizedImage,
    ExplanationsToolsComponent,
  ],
})
export class AuthDialogModule {}
